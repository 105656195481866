import * as focusTrap from 'focus-trap'; // ESM
import instantsearch from 'instantsearch.js';
import algoliasearch from 'algoliasearch';
import { searchBox, hits, refinementList, pagination, hierarchicalMenu, currentRefinements, sortBy, clearRefinements, stats } from 'instantsearch.js/es/widgets';
import Cookies from 'js-cookie'
import lightGallery from 'lightgallery';
import { Popover } from 'bootstrap';

export default {
  init() {

    var searchInit = false;

    $(document).on('keyup', function (e) {
      if (e.key == "Escape") {
        showSearch(false);
      }
    });

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl)
    })

    lightGallery(document.getElementById("gallery-container"), {
      speed: 500,
      hideBarsDelay: 2000,
      selector: '.gallery-item',
      download: false,
      height: '90%',
    });

    $(".data-update").click(function (e) {
      e.preventDefault();
      $(this).closest('.nav').find(".data-update").removeClass('active');
      $(this).addClass('active');
      chart[$(this).attr('chart')].data = js_obj_data[$(this).attr('chart')]['prepared_data'][$(this).attr('header')];
    });

    // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new Tooltip(tooltipTriggerEl)
    // })

    $.fn.focusWithoutScrolling = function () {
      var x = window.scrollX, y = window.scrollY;
      this.focus();
      window.scrollTo(x, y);
      return this; //chainability

    };

    window.addEventListener('ccc.loaded', function (e) {
      var dialog = $('#ccc');
      if (ccc.consentSubmitted == false) {
        dialog.attr("tabindex", "-1");
        dialog.focusWithoutScrolling();
      }
      dialog.attr("role", "dialog");
      dialog.attr("aria-label", "Cookie banner");

      document.addEventListener("focus", function (event) {
        if (!dialog.has(event.target).length) {
          event.stopPropagation();
          dialog.focusWithoutScrolling();
        }
      }, true);

    });

    $('g:has(> g[stroke="#3cabff"])').hide();

    function searching(query) {
      if (query.length > 0) {
        showSearch(true);
      }
    }

    const trapContainer = $('#search-container');
    var trap = focusTrap.createFocusTrap('#search-container', {
      clickOutsideDeactivates: true,
      allowOutsideClick: true,
      onActivate: () => trapContainer.addClass('is-active'),
      onDeactivate: () => trapContainer.removeClass('is-active'),
    });

    if ($('body').hasClass('datasheets') || $('body').hasClass('bibliotheek')) {
      if (searchInit == false) {
        searchInit = true;
        console.log('init search');
        initSearch();

      }
    }

    $('.search-container').click(function () {


      if (searchInit == false) {
        searchInit = true;
        console.log('init search');
        initSearch();
        showSearch(true);

      }

    });

    function showSearch(show) {

      if ($('.search-container').length) {


        if (show) {
          //trap.activate();
          $('.search-container').addClass('show');
          $('.backdrop').addClass('show');
        }
        else {
          //trap.deactivate();
          $('.search-container').removeClass('show');
          $('.backdrop').removeClass('show');
        }
      }
    }

    function initSearch() {

      if ($('#full-search-box').length) {
        var refinement = {};
        if ($('#full-search-box').attr('data-refinement')) {
          refinement = { "type.name": [$('#full-search-box').attr('data-refinement')] };
        }
        var search = instantsearch({
          appId: 'latency',
          insights: true,
          searchClient: algoliasearch(
            'YDC4L94MK2',
            'fe77d5d724fe4697a71a8ec9525e5608'
          ),
          indexName: 'Wordpress',
          routing: true,
          initialUiState: {
            Wordpress: {
              refinementList: refinement
            },
          },

        });

        search.addWidgets([
          searchBox({
            container: '#full-search-box',
            placeholder: 'Voer uw zoekterm in',
            templates: {
              submit: '<ion-icon name="search"></ion-icon>',
              reset: '<ion-icon name="close-circle d-none"></ion-icon>',

            },
            cssClasses: {
              input: ['form-control search-padding']
            },
            queryHook(query, search) {
              searching(query);
              search(query);
            },
          }),
          clearRefinements({
            container: '#clear-refinements',
          }),
          currentRefinements({
            container: '#current-refinements', // Specify the container where the widget should be rendered
            // templates: {
            //   item(data) {
            //     return `${data.label}: ${data.refinements.map(refinement => refinement.label).join(', ')}`;
            //   }
            // },
            // cssClasses: {
            //   item: 'badge bg-secondary'
            // }
          }),
          stats({
            container: '#count',
            templates: {
              text: `{{nbHits}} resultaten`,
            }
          }),
          pagination({
            container: '#full-pagination',
          }),
          hits({
            container: '#full-best-result',
            templates: {
              item: `
                  <a href="{{ url }}" class="no-style {{ type.name }}">
                  <div class="library-result position-relative bg-white rounded enlarge" style="border-color:{{ color }}">
                  <div aria-label="Publicatietaal is {{ language }}" class="language lang-{{ language }}"></div>
                  <div class="row d-flex align-items-center">
                  <div class="col-3 col-md-2 ">
                  <img class="img-fluid " src="{{artwork}}" alt="">
                  </div>
                  <div class="col">
                  <div class="p-3 pe-5">
                  <span lang="{{titleLanguage}}">{{#helpers.highlight}}{ "attribute": "title" }{{/helpers.highlight}}</span>
                  <div class="subtitle fw-normal">{{#helpers.highlight}}{ "attribute": "subtitle" }{{/helpers.highlight}}</div>
                  <div class="search-meta">
                  {{ type.name }}<span class="pe-2 details">, Gepubliceerd op {{ date }}, </span>
                    <span aria-hidden="true" class="details">Auteurs: {{ authors }}</span>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </a>`,
              empty: 'Geen resultaten gevonden voor <q>{{ query }}</q>',
            },
          }),
          hierarchicalMenu({
            container: '#full-categories-filter',
            attributes: ['categories.lvl0', 'categories.lvl1', 'categories.lvl2', 'categories.lvl3'],
            showMore: true,
            limit: 5,
            sortBy: ['name:asc', 'count:desc'],
            templates: {
              showMoreText: `
                {{#isShowingMore}}
                  Minder
                {{/isShowingMore}}
                {{^isShowingMore}}
                Meer
                {{/isShowingMore}}
              `,
            }
          }),
          hierarchicalMenu({
            container: '#full-dates-filter',
            attributes: ['dates.lvl0', 'dates.lvl1'],
            showMore: true,
            limit: 5,
            sortBy: ['name:desc', 'count:desc'],
            templates: {
              showMoreText: `
                  {{#isShowingMore}}
                  Minder
                  {{/isShowingMore}}
                  {{^isShowingMore}}
                    Meer
                  {{/isShowingMore}}
                `,
            }
          }),
          refinementList({
            container: '#full-type-filter',
            attribute: 'type.name',

          }),
          refinementList({
            container: '#full-authors-filter',
            attribute: 'authors',
            showMore: true,
            limit: 5,
            showMoreLimit: 500,
            sortBy: ['name:asc', 'count:desc'],
            templates: {
              showMoreText: `
                  {{#isShowingMore}}
                  Minder
                  {{/isShowingMore}}
                  {{^isShowingMore}}
                    Meer
                  {{/isShowingMore}}
                `,
            }
          }),
          refinementList({
            container: '#language-filter',
            attribute: 'language',
            showMore: false,
            limit: 5,
            showMoreLimit: 500,
            sortBy: ['name:asc', 'count:desc'],
            templates: {
              showMoreText: `
                  {{#isShowingMore}}
                  Minder
                  {{/isShowingMore}}
                  {{^isShowingMore}}
                    Meer
                  {{/isShowingMore}}
                `,
            }
          }),
          //bestResult($('#best-result'))
        ]
        );

        search.start();

        var added = false;

        search.on('render', () => {
          $('.ais-SearchBox-input').focus();
          $('.ais-SearchBox-input').attr('aria-label', "Voer uw zoekterm in");
          $('.ais-ClearRefinements-button').text('Filters wissen');

          $('.ais-SearchBox-input').click(function () {
            showSearch(true);
          });

          $('.search-container-close').click(function () {
            showSearch(false);
          });

          $('.search-container-close').keypress(function (e) {
            if (e.which == 13) {//Enter key pressed
              $('.search-container-close').click();//Trigger search button click event
            }
          });

          $('.backdrop').click(function () {
            showSearch(false);
          });


          const searchForm = $("#full-search-box");
          const searchFormInput = $(".ais-SearchBox-input");
          const info = $(".info");


          // The speech recognition interface lives on the browser’s window object
          const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition; // if none exists -> undefined

          if (SpeechRecognition) {
            console.log("Your Browser supports speech Recognition");

            const recognition = new SpeechRecognition();
            recognition.continuous = true;
            recognition.lang = "nl-NL";

            if (added == false) {
              added = true;
            }

            const micBtn = $("#audio-search");

            var ready = true;

            micBtn.on("click", micBtnClick);
            function micBtnClick() {
              console.log(ready);
              if (ready == true) {
                recognition.start();
                ready = false;
              }
              else {
                recognition.stop();
                ready = true;
              }
            }

            recognition.addEventListener("start", startSpeechRecognition);
            function startSpeechRecognition() {
              $(".pulse").show();
              searchFormInput.focus();
              console.log("Voice activated, SPEAK");
            }

            recognition.addEventListener("end", endSpeechRecognition);
            function endSpeechRecognition() {
              $(".pulse").hide();
              searchFormInput.focus();
              console.log("Speech recognition service disconnected");
            }

            recognition.addEventListener("result", resultOfSpeechRecognition);
            function resultOfSpeechRecognition(event) {
              showSearch(true);
              const current = event.resultIndex;
              const transcript = event.results[current][0].transcript;

              if (transcript.toLowerCase().trim() === "stop recording") {
                recognition.stop();
              }
              else if (!searchFormInput.value) {
                search.helper.setQuery(transcript).search()

              }
              else {
                if (transcript.toLowerCase().trim() === "go") {
                  searchForm.submit();
                }
                else if (transcript.toLowerCase().trim() === "reset input") {
                  search.helper.setQuery("").search()

                }
                else {
                  search.helper.setQuery(transcript).search()
                }
              }

            }

            info.textContent = 'Voice Commands: "stop recording", "reset input", "go"';

          }
          else {
            console.log("Your Browser does not support speech Recognition");
            info.textContent = "Your Browser does not support Speech Recognition";
          }

        });

      }

    }

    $('.complete-link').click(function () {
      window.location = $(this).find('a').attr('href');
      return false;
    });

    var nieuwsbrief_popup = Cookies.get('nieuwsbrief_popup');
    if (nieuwsbrief_popup == undefined || nieuwsbrief_popup == '') {
      Cookies.set('nieuwsbrief_popup', 'close', { expires: 14 });
      setTimeout(function () {
        $('.nieuwsbrief-popup').show(200);
      }, 5000);
    }
    else if (nieuwsbrief_popup != 'close') {

    }

    $('.nieuwsbrief-popup .close-popup').click(function () {
      $('.nieuwsbrief-popup').hide(200);
    });

    $('#doorgaan').click(function (e) {
      e.preventDefault();
      var selectedValue = $('input[name=hoe-vaak]:checked').val();
      window.location = "https://energy.nl/gebruikersonderzoek/?hoe-vaak=" + selectedValue;
    });

    // find .ais-ClearRefinements-button and change the text to Reset Filters
  

    // var enquete_popup = Cookies.get('enquete_popup');
    // if (enquete_popup == undefined || enquete_popup == '') {
    //   Cookies.set('enquete_popup', 'close', { expires: 1 });
    //   setTimeout(function() {
    //     $('.enquete-popup').show(200);
    //   }, 5000);
    // }
    // else if (enquete_popup != 'close'){

    // }

    // $('.enquete-popup .close-popup').click(function () {
    //   $('.enquete-popup').hide(200);
    // });

    if (location.hash) {
      $(location.hash + " .long").addClass('show');
      jQuery("#btnradio2").trigger("click");
    }

    $(".form-switch").change(function (e) {
      e.preventDefault();
      if ($(this).attr('action') == 'close') {
        $($(this).attr('target') + " .long").removeClass('show');
        location.hash = '';
      }
      else {
        $($(this).attr('target') + " .long").addClass('show');
        location.hash = $(this).attr('target');
      }
    });

    var bookmarks = Cookies.get('bookmarks');
    if (bookmarks == undefined || bookmarks == '') {
      Cookies.set('bookmarks', '');
      bookmarks = [];
    }
    else {
      bookmarks = bookmarks.split(',');
    }


    if ($('#bookmarks').length && bookmarks.length > 0) {

      $.post(ajaxurl + "?entries=" + bookmarks.join(','), {
        action: 'load_bookmarks'
      }).done(function (data) {
        $("#bookmarks").append(data);
      });

    }
    else{
      $("#bookmarks").append('<p>Geen bookmarks gevonden.</p>');
    }

    $('.js-loadMore').click(function (e) {
      e.preventDefault();

      var page = $(this).attr('page');
      var limit = $(this).attr('limit');
      var section = $(this).attr('section');
      var categories = $(this).attr('categories');
      var authors = $(this).attr('authors');
      var design = $(this).attr('design');
      var width = $(this).attr('width');
      var append = $(this).attr('append');
      var template = $(this).attr('template');

      var queryString = 'page=' + page + '&limit=' + limit + '&section=' + section + '&categories=' + categories + '&design=' + design + '&width=' + width + '&template=' + template;
      if (authors) {
        queryString += '&authors=' + authors;
      }
      $(this).attr('page', (parseInt(page) + 1));
      $(this).addClass('pulse-primary');
      $.post(ajaxurl + '?' + queryString, {
        action: 'load_posts'
      }).done(function (data) {
        $('.appended-post').removeAttr('id');
        $(append).before(data);
        $('#focus-post a').focus();
        $('.js-loadMore').removeClass('pulse-primary');
      });

    });


    $(".bookmark").each(function () {
      var id = $(this).attr('data-id');
      if (bookmarks.includes(id)) {
        $(this).addClass('active');
      }
    });

    $('.bookmark').click(function (e) {
      e.preventDefault();
      var id = $(this).attr('data-id');
      var index = bookmarks.indexOf(id);
      if (index > -1) {
        bookmarks.splice(index, 1);
      }
      else {
        bookmarks.push(id);
      }
      $(this).toggleClass('active');
      Cookies.set('bookmarks', bookmarks.join(','));
    });

    var page = 2;
    $('#loadMore').click(function (e) {
      e.preventDefault();
      $.get("/blog?page=" + page, function (data) {
        $("#news-entries").append(data);
        page++;
      });
    });


    // JavaScript to be fired on all pages
    function toggleAffix(affixElement, height) {
      if (jQuery(document).scrollTop() > height) {
        $('#navFill').height(height);
        affixElement.addClass('affix');
      }
      else {
        $('#navFill').height(0);
        affixElement.removeClass('affix');
      }
    }


    jQuery('[data-toggle="affix"]').each(function () {
      var ele = jQuery(this);
      var height = ele.offset().top;
      jQuery(window).on('scroll resize', function () {
        toggleAffix(ele, height);
      });
    });


    $('.nav-icon3').click(function () {
      $(this).toggleClass('open');
    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    jQuery(document).ready(function ($) {
      var delay = 300; setTimeout(function () {
        $('.elementor-tab-title').removeClass('elementor-active');
        $('.elementor-tab-content').css('display', 'none');
      }, delay);
    });
  },
};
